const mutations = {
  setStats(state, payload) {
    state.stats = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setSubmissions(state, payload) {
    state.submissions = payload;
  },
  setMeta(state, payload) {
    state.meta = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setConfig(state, payload) {
    state.config = payload;
  },
  // setIsLinkedinUser(state, payload) {
  //   state.isLinkedinUser = payload;
  // },
  setHighlights(state, payload) {
    state.highlights = payload;
  },
  appendAllSubmissions(state, payload) {
    state.allsubmissions.push(...payload);
  },
  resetSubmissions(state) {
    state.allsubmissions.splice(0);
    // this.$set(state, "submissions", []);
  },
  appendUsers(state, payload) {
    state.users.push(...payload);
  },
  resetUsers(state) {
    state.users.splice(0);
    // this.$set(state, "submissions", []);
  },
  setAcademy(state, payload) {
    state.academy = payload;
  },
};

export default mutations;
