import Vue from "vue";
import App from "./App.vue";
import { store } from "./store";
import { i18n } from "./i18n";
import router from "./router";
import "./quasar";
import Icons from "@/mixins/Icons";
import AudioVisual from "vue-audio-visual";



Vue.config.productionTip = false;
Vue.use(router);

Vue.mixin({
  methods: {
    showError(err) {
      console.log(err);
      this.$q.notify({
        type: "negative",
        message: this.$t("err"),
      });
    },
  },
});

Vue.use(AudioVisual);
Vue.mixin(Icons);

new Vue({
  i18n,
  router,
  render: (h) => h(App),
  store,
}).$mount("#app");
