import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, useAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  functions_url: process.env.VUE_APP_FUNCTION_URL,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

// firebase.initializeApp(firebaseConfig);
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
// export const storage = getStorage(firebaseApp);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          unsubscribe();
          resolve(user);
        } else resolve(null);
      },
      reject
    );
  });
};

if (process.env.VUE_APP_EMULATORS) {
  useAuthEmulator(auth, "http://localhost:9099");
  // useStorageEmulator(storage, "localhost", 9199);
}

export const firebaseurl = firebaseConfig.functions_url;
