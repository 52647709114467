import filter from "lodash/filter";
import includes from "lodash/includes";
import find from "lodash/find";
import { DateTime } from "luxon";

const getters = {
  getTagIcon(state) {
    return (tag) => {
      let icon = state.config.tag_options[tag] ?? "sell";
      return icon;
    };
  },
  getStats(state) {
    return state.stats;
  },
  getSubmissions(state) {
    return state.submissions;
  },
  getHighlights(state) {
    return state.highlights;
  },
  getUser(state) {
    return state.user;
  },
  getAllSubmissions(state) {
    return state.allsubmissions;
  },
  getUsers(state) {
    return state.users;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  getError(state) {
    return state.error;
  },
  getConfig(state) {
    return state.config;
  },
  getMeta(state) {
    return state.meta;
  },
  isOnWhiteList: (state) => (wantedphase) => {
    if (state.config == null) return false;

    let phase_to_check = wantedphase || state.config.current_phase;

    const currentPhase = find(state.config.phases, {
      code: phase_to_check,
    });

    if (
      currentPhase.whitelist &&
      state.meta &&
      state.meta.onWhiteList &&
      state.meta.onWhiteList[phase_to_check] === false
    ) {
      console.log("not on whitelist");
      return false;
    }
  },

  canSubmit: (state) => (wantedphase) => {
    // console.log("CAN SUBMIT?");

    // return true;
    if (state.config == null) return false;

    // console.log("checking phase");
    let phase_to_check = wantedphase || state.config.current_phase;

    // console.log(`Wanted phase: ${phase_to_check}`);

    // console.log("Checking if can submit");
    // console.log(state.config);

    const currentPhase = find(state.config.phases, {
      code: phase_to_check,
    });

    if (!currentPhase.hasSubmission) return false;

    //got a pending state in this phase?
    let can = filter(state.submissions, (s) => {
      return (
        includes(
          ["draft", "submitted", "readyformoderation", "transcoded"],
          s.status
        ) && s.phase == currentPhase.code
      );
    });

    if (can.length) return false;

    //check if they have reached the limit for this phase:
    const limit = currentPhase.maxSubmissions;

    // console.log(`Limit: ${limit}`);

    let done = filter(state.submissions, (s) => {
      return (
        includes(["edited", "published", "moderated"], s.status) &&
        s.phase == currentPhase.code
      );
    });

    // console.log(`Done: ${done.length}`);

    //over limit for this phase?
    if (limit && done.length >= limit) return false;

    //not whitelisted:
    // console.log(state.meta);
    //if there is a whitelist, and I'm not on it:
    if (
      currentPhase.whitelist &&
      state.meta &&
      state.meta.onWhiteList &&
      state.meta.onWhiteList[phase_to_check] === false
    ) {
      console.log("not on whitelist");
      return false;
    }

    //outside of date range for this phase?
    if (DateTime.fromSeconds(currentPhase.closesAt._seconds).diffNow() < 0) {
      console.log("out of range");
      return false;
    }

    return true;
  },
};

export default getters;
