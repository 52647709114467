import {
  outlinedAdd,
  outlinedDelete,
  outlinedLightbulb,
  outlinedEmojiEvents,
  outlinedGavel,
  outlinedFileUpload,
  outlinedPolicy,
  outlinedEmail,
  outlinedPublic,
  outlinedStyle,
  outlinedTranslate,
  outlinedSend,
  outlinedWest,
  outlinedHourglassBottom,
  outlinedSettings,
  outlinedInfo,
  outlinedWarning,
  outlinedDangerous,
  outlinedDescription,
  outlinedGroup,
  outlinedPerson,
  outlinedGroups,
  outlinedEmojiObjects,
  outlinedMovie,
  outlinedChevronRight,
  outlinedMarkEmailRead,
  outlinedArrowBack,
  outlinedLanguage,
  outlinedUpload,
  outlinedMoreHoriz,
  outlinedPendingActions,
  outlinedAutoFixHigh,
  outlinedShare,
  outlinedSouth,
  outlinedLogout,
  outlinedEdit,
  outlinedPlayArrow,
  outlinedDownload,
  outlinedCheck,
  outlinedNewspaper,
  outlinedLocationCity,
  outlinedMyLocation,
  outlinedDiversity2,
  outlinedDiversity3,
  outlinedFormatQuote,
  outlinedEvent,
  outlinedRateReview,
  outlinedNorthEast,
  outlinedMenu,
  outlinedExpandMore,
  outlinedExpandLess,
  outlinedStop,
  outlinedClear,
  outlinedFullscreen,
  outlinedQuestionMark,
} from "@quasar/extras/material-icons-outlined";

// import { fabLinkedinIn, farEnvelope } from "@quasar/extras/fontawesome-v5";

export default {
  created() {
    this.matClear = outlinedClear;
    this.matStop = outlinedStop;
    this.matExpandMore = outlinedExpandMore;
    this.matExpandLess = outlinedExpandLess;
    this.matMenu = outlinedMenu;
    this.matNorthEast = outlinedNorthEast;
    this.matRateReview = outlinedRateReview;
    this.matNewspaper = outlinedNewspaper;
    this.matPlayArrow = outlinedPlayArrow;
    this.matCheck = outlinedCheck;
    this.matDownload = outlinedDownload;
    this.matEdit = outlinedEdit;
    this.matLogout = outlinedLogout;
    this.matSouth = outlinedSouth;
    this.matShare = outlinedShare;
    this.matAutoFixHigh = outlinedAutoFixHigh;
    this.matPendingActions = outlinedPendingActions;
    this.matMoreHoriz = outlinedMoreHoriz;
    this.matUpload = outlinedUpload;
    this.matLanguage = outlinedLanguage;
    this.matChevronRight = outlinedChevronRight;
    this.matMovie = outlinedMovie;
    this.matEmojiObjects = outlinedEmojiObjects;
    this.matGroup = outlinedGroup;
    this.matPerson = outlinedPerson;
    this.matGroups = outlinedGroups;
    this.matDescription = outlinedDescription;
    this.matInfo = outlinedInfo;
    this.matWarning = outlinedWarning;
    this.matDangerous = outlinedDangerous;
    this.matHourglassBottom = outlinedHourglassBottom;
    this.matSettings = outlinedSettings;
    this.matAdd = outlinedAdd;
    this.matDelete = outlinedDelete;
    this.matPolicy = outlinedPolicy;
    this.matEmail = outlinedEmail;
    this.matPublic = outlinedPublic;
    this.matStyle = outlinedStyle;
    this.matTranslate = outlinedTranslate;
    this.matSend = outlinedSend;
    this.matWest = outlinedWest;
    this.matAdd = outlinedAdd;
    this.matLightbulb = outlinedLightbulb;
    this.matEmojiEvents = outlinedEmojiEvents;
    this.matGavel = outlinedGavel;
    this.matFileUpload = outlinedFileUpload;
    this.matMarkEmailRead = outlinedMarkEmailRead;
    this.matArrowBack = outlinedArrowBack;
    this.matLocationCity = outlinedLocationCity;
    this.matMyLocation = outlinedMyLocation;
    this.matDiversity2 = outlinedDiversity2;
    this.matDiversity3 = outlinedDiversity3;
    this.matFormatQuote = outlinedFormatQuote;
    this.matEvent = outlinedEvent;
    this.matFullscreen = outlinedFullscreen;
    this.matQuestion = outlinedQuestionMark;
  },
};
