<template lang="pug">
q-layout(view="lHh Lpr lff")
  //- q-toolbar.bg-warning.z-max.fixed-top
  //-   .text-center.text-bold Our email is experiencing issues. Please send any questions or enquiries to solferino.academy@ifrc.org, starting your email title with &quot;LIMITLESS SUPPORT -&quot;
  //- q-header(style="height:70px;z-index:2001;margin-top:50px;" :elevated="!isRoute('/') || snap" ).position-relative.bg-white
  q-header(style="height:70px;z-index:2001;" :elevated="!isRoute('/') || snap" ).position-relative.bg-white
    q-toolbar(ref="toolbar" v-scroll="scrolled").full-height.items-center
      //- .col-auto(style="width:40px;")
      //-   q-btn.on-left(
      //-     :icon="whichIcon",
      //-     flat,
      //-     dense,
      //-     round,
      //-     color="dark"
      //-     :to="whereToGo",
      //-     v-if="canShowBtn",
      //-     aria-label="back"
      //-   )
      .col-auto(style="padding-top:6px;padding-left:6px;")
        router-link(to="/")
          img.logo(src="~@/assets/img/logo_green.webp" alt="Limitless Logo")
      q-space.xs-hide
      //- .text-h4.text-grey-9 Kuento
      //- img(src="./assets/Logo_White.svg" style="height:25px")
      //- a.solferino.absolute-center(
      //-   href="https://solferinoacademy.com",
      //-   target="_blank"
      //- )
      //- img(src="~@/assets/img/solferino.svg")
      q-space.q-my-md
      .gt-sm
        q-btn(flat :color="(isRoute('/'))?'primary':'dark'" to="/" no-caps :class="{'thispage':isRoute('/')}") {{ $t('home') }}
        q-btn(flat :color="(isRoute('/academy'))?'primary':'dark'" to="/academy" no-caps :class="{'thispage':isRoute('/academy')}") {{ $t('academy.title') }}
        q-btn(flat :color="(isRoute('/lead'))?'primary':'dark'" to="/lead" no-caps :class="{'thispage':isRoute('/lead')}") {{ $t('lead.title') }}
        q-btn(flat v-if="user" :color="(isRoute('/submission'))?'primary':'dark'" to="/submission" no-caps :class="{'thispage':isRoute('/submission')}") {{ $t('mylimitless') }}
        q-btn(@click="signOut", color="dark" flat, v-if="user" no-caps) {{ $t('logout') }}
      LocaleSwitcher.gt-sm.q-ml-sm
      .lt-md
        q-btn(:icon-right="matMenu" color="dark" dropdown-icon="none" flat square aria-label="Main menu")
          q-menu
            q-list(separator)
              q-item.q-pt-md(clickable v-close-popup to="/" exact) {{ $t('home') }}
              q-item.q-pt-md(clickable v-close-popup to="/academy" exact) {{ $t('academy.title') }}
              q-item.q-pt-md(clickable  v-close-popup to="/lead" exact) {{ $t('lead.title') }}
              q-item.q-pt-md(clickable v-if="user" v-close-popup to="/submission" exact) {{ $t('mylimitless') }}
              q-item.q-pt-md(clickable v-close-popup v-if="user" @click="signOut") {{ $t('logout') }}
              q-item.q-pt-xs.q-pb-none
                LocaleSwitcher           

  q-page-container(style="min-height:150vh")
    router-view(:key="$route.fullPath", :logo="logo", :filter="filter" v-on:startLogin="startLogin" :doLogin="startLogin")
  //- q-page-sticky(position="right")
  //-   .column.bg-primary.text-white.text-center(style="max-width:70px;border-left:1px solid white;border-top:1px solid white;border-bottom:1px solid white;font-weight:500;font-size:12px;")
  //-     .col-auto.q-pt-md
  //-       div {{ $t('watch-all-content') }}
  //-     .col
  //-       q-btn.q-pa-sm(:icon="matYouTube" flat target="_BLANK" href="https://www.youtube.com/@SolferinoVoices")
  q-footer(style="padding-bottom:50px;").bg-secondary.z-min
    div.bg-white
      div
        .row.justify-center.text-black
          .q-pt-xl.q-px-md
            .text-h2.text-center {{ $t('thanks-to-our-partners') }}

        .bg-white.q-mb-xl
          .row.items-center.justify-center.bg-white.q-pt-lg(style="max-width:1400px;margin:auto auto")
            .col-12
              .row.justify-center
                .col-12.col-md-5.text-center
                    
                  q-img(
                    src="/img/partners/eu1.webp",
                    alt="EU"
                    contain
                  )
                .col-12.col-md-5.text-center
                  
                    q-img(
                  src="/img/partners/euyouth.webp",
                  alt="EU YM"
                  contain
                )
            .col-6.col-md-3.text-center
              a(href="https://globalyouthmobilization.org/" target="_BLANK" aria-label="Link to Global Youth Mobilization")
                q-img(
              src="/img/partners/eu.webp",
              alt="Global Youth Mobilization Logo"
              contain
            )
            .col-6.col-md-3.text-center.q-px-xl
              a(href="https://www.fdfa.admin.ch/sdc" target="_BLANK" aria-label="Link to Swiss Agency for Development and Cooperation")
                q-img(
              src="/img/partners/swiss.webp",
              alt="Swiss Agency for Development and Cooperation Logo"
              contain
            )
            .col-6.col-md-3.text-center.q-px-xl
              a(href="https://monash.edu/" target="_BLANK" aria-label="Link to Monash University")
                q-img(
              src="/img/partners/monash.webp",
              alt="Monash Logo"
              contain
            )
            .col-6.col-md-3.text-center.q-px-xl
              a(href="https://northumbria.ac.uk/" target="_BLANK" aria-label="Link to Northumbria University")
                q-img(
              src="/img/partners/northumbria.webp",
              alt="Northumbria Logo"
              contain
            )
            .col-6.col-md-3.text-center
              a(href="https://www.redcross.org/" target="_BLANK" aria-label="Link to American Red Cross")
                q-img(
              src="/img/partners/am.webp",
              alt="American Red Cross Logo"
              contain
            )
        .row.justify-center.text-black
          .q-px-md.q-pb-lg
            .text-h2.text-center {{ $t('our-supporters') }}
        .bg-white.q-mb-xl.q-mt-lg
          .row.items-center.justify-around.q-pb-xl.q-mb-xl(style="max-width:800px;margin:auto auto")
            .col-6.col-md-3.text-center.q-px-md
              a(href="https://rivet.org/" target="_BLANK" aria-label="Link to Rivet")
                q-img(
              src="/img/partners/rivet.webp",
              alt="Solferino Academy logo"
              contain
            )
            .col-6.col-md-3.text-center.q-px-xl
              a(href="https://cri.it/" target="_BLANK" aria-label="Link to Italian Red Cross")
                q-img(
              src="/img/partners/it.webp",
              alt="Italian Red Cross logo"
              contain
            )
            .col-6.col-md-3.text-center
              a(href="https://www.rodekruis.nl/" target="_BLANK" aria-label="Link to NL Red Cross")
                q-img(
              src="/img/partners/nl.webp",
              alt="NL Red Cross logo"
              contain
            )
            .col-6.col-md-3.text-center.q-px-md
              a(href="https://www.climatecentre.org/" target="_BLANK" aria-label="Link to Climate Centre")
                q-img(
              src="/img/partners/climate.webp",
              alt="Climate Centre logo"
              contain
            )

      
    .row.q-col-gutter-md.items-stretch.bg-secondary
      .col-12.full-height
        .q-pa-sm.text-center
          .text-white.q-mb-lg 
            div(v-if="user")
              div {{ $t('if-there-are-any-updates-well-get-in-touch-with-you-at') }}
                span.q-px-xs.text-accent {{user.email}}
            div {{ $t('for-support-contact') }} &nbsp;
              a.text-accent(href="mailto:support.limitless@solferinoacademy.com") support.limitless@solferinoacademy.com
          div {{ $t('oss') }}

          a.text-accent(
            target="_blank",
            href="https://gitlab.com/action-lab-aus/kuento"
          ) {{ $t('view_code') }} - v{{version}}
        
          div {{ $t('othercontent') }}
          
              
          div
            a(href="/policy").text-accent {{ $t('terms_privacy_policy') }}
  //- EMAIL LOGIN DIALOG (CHANGED FOR GIT)
  q-dialog(v-model="emailLogin")
    q-card(style="width: 800px; max-width: 90vm")
      q-card-section
        .text-h2.q-mb-md.text-center {{ $t('enter_your_email_to_receive_a_one-time_link') }}
        .relative-position.q-mt-xl
          q-input.text-body1(
            ref="emailinput",
            outlined
            type="email",
            v-model="email",
            reactive-rules,
            :rules="[(val) => isEmail(val) || $t('email_address_is_required')]"
          )
          .floatinglabel
            .row
              .col-auto
                .bg-accent.q-pa-xs.text-dark {{$t('email')}}

      //- q-separator
      q-card-actions(align="center").q-ma-sm.q-pt-none
        q-btn.full-width(
          align="between"
          :disable="isDisabled()",
          @click="sentEmail = true; signInWithEmail()",
          filled
          unelevated
          no-caps
          size="lg"
          color="primary"
          :icon-right="matNorthEast"
        )
          //- q-icon(left, :name="farEnvelope")
          div {{ $t('send_me_an_email') }}
      q-inner-loading.bg-primary.q-pa-md(:showing="sentEmail")
        .text-body1.text-center.text-white(v-if="sentEmail")
          div {{ $t('signinlink') }}
          a.text-body1.text-white(href="mailto:support.limitless@solferinoacademy.com" style="word-break: break-all;") support.limitless@solferinoacademy.com

  //- q-dialog(v-model="mainLogin")
  //-   q-card.text-center(style="width: 800px; max-width: 90vm")
  //-     .text-h5.q-ma-md {{ $t('start_login') }}
  //-     q-card-section(horizontal)
  //-       q-card-section.q-pa-lg.col
  //-         q-btn(stack, flat, @click="mainLogin = false; emailLogin = true")
  //-           //- q-icon(:name="farEnvelope", size="lg")
  //-           div {{ $t('email_login') }}
  cookie-law.bg-secondary.text-white(ref="cookies" style="border-top:white solid 1px")
    .full-width(slot-scope="props")
      .row.full-width
        .col-12.q-mb-sm
          div(slot="message")
            | {{ $t('cookie_message') + ' ' }}
            router-link.text-white(to="/policy") {{ $t('read_more') }}
        .col-auto.q-mb-sm
          q-btn.on-left(@click="props.decline()", outline) {{ $t('use_cookies') }}
        .col-auto
          q-btn.on-left(@click="addGA(); props.accept()", outline) {{ $t('use_cookies_and_google') }}
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import LocaleSwitcher from "@/components/LocaleSwitcher";
  // import FilterControls from "@/components/FilterControls";
  import CookieLaw from "vue-cookie-law";
  import { debounce } from "quasar";
  import { sendSignInLinkToEmail } from "firebase/auth";
  import { auth } from "@/db";

  /* eslint-disable no-control-regex */
  const emailreg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  export default {
    name: "App",
    data: () => {
      return {
        version: process.env.PACKAGE_VERSION,
        logo: {
          display: true,
          filter: false,
        },
        snap: false,
        filter: {
          region: null,
          tags: [],
        },
        email: "",
        emailLogin: false,
        mainLogin: false,
        sentEmail: false,
      };
    },
    async mounted() {
      this.authAction();
      // console.log(this.$refs.cookies.isAccepted());
      if (this.$refs.cookies.isAccepted()) this.addGA();
    },
    meta() {
      return {
        title: this.$t("page_title"),
        meta: {
          description: {
            name: "description",
            content: this.$t("phases.phase_1.description"),
          },
          keywords: {
            name: "keywords",
            content: Object.values(this.$t("tags")).join(", "),
          },
        },
      };
    },
    components: { LocaleSwitcher, CookieLaw },
    computed: {
      whereToGo() {
        // console.log(this.$route.name);
        switch (this.$route.name) {
          case "about":
            return "/submission";
          case "live":
          case "academy":
            return "/";
          case "submission":
            return "/about";
          default:
            return "/";
        }
      },
      whichIcon() {
        // console.log(this.$route.name);
        switch (this.$route.name) {
          case "about":
            return this.matPerson;
          default:
            return this.matArrowBack;
        }
      },
      canShowBtn() {
        return this.$route.name != "root";
      },
      canShowHeader() {
        console.log(this.$route.name);
        return this.$route.name !== "about" && this.$route.name !== "root";
      },
      ...mapState(["user", "config"]),
    },
    methods: {
      scrolled: debounce(function() {
        // console.log(this.$refs.toolbar.$el.getBoundingClientRect().bottom);

        this.snap = window.pageYOffset > 0;
        // console.log(this.$refs.toolbar.getBoundingClientRect().top - 70 < 0);
      }, 10),
      async signInWithEmail() {
        // console.log(window.location.hostname);
        const actionCodeSettings = {
          url:
            window.location.hostname === "localhost"
              ? "http://localhost:8080/"
              : process.env.VUE_APP_WEB_APP,
          handleCodeInApp: true,
        };

        auth.languageCode =
          window.navigator.userLanguage || window.navigator.language;

        sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
          .then(() => {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem("emailForSignIn", this.email);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(`${errorCode}: ${errorMessage}`);
          });
      },
      isEmail(val) {
        return emailreg.test(val);
      },
      isDisabled() {
        if (!this.$refs.emailinput) return true;
        // console.log(this.$refs.emailinput.hasError);
        return this.$refs.emailinput.hasError;
      },
      startLogin() {
        if (this.user) this.$router.push("/submission");
        else this.emailLogin = true;
      },
      isRoute(page) {
        // console.log(page);
        // console.log(this.$route.path);

        if (
          this.$route.path.startsWith("/submission") &&
          page === "/submission"
        )
          return true;
        else return this.$route.path === page;
      },
      deleteGA(cookieconsent_name) {
        var keep = [cookieconsent_name, "DYNSRV"];

        document.cookie.split(";").forEach(function(c) {
          c = c.split("=")[0].trim();
          if (!~keep.indexOf(c))
            document.cookie =
              c + "=;" + "expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
        });
      },
      addGA() {
        console.log(`Adding GA for ${process.env.VUE_APP_GOOGLE_TAG}`);
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = "true";
        s.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_TAG}`;
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);

        //it is absolutely crucial to define gtag in the global scope
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", process.env.VUE_APP_GOOGLE_TAG, {
          anonymize_ip: true,
          debug_mode: true,
        });
      },

      async signOut() {
        await this.signOutAction();
        this.$router.replace("/");
      },
      ...mapActions(["authAction", "signOutAction"]),
    },
  };
</script>

<style lang="scss" scoped>
  // @import "/styles/quasar.variables.scss";
  // @import "~quasar-variables-styl";

  .borderme {
    border-bottom: #eee solid 1px;
  }

  .logo {
    opacity: 1;
    transition: opacity 0.3s;
    width: 120px;
    height: 43px;
  }

  .logogone {
    opacity: 0;
  }

  .solferino {
    width: 130px;
    margin-top: 4px;
  }

  .q-header--bordered {
    border-color: #aaa;
  }

  .thispage::after {
    // transform: translate(0px, 0px) skew(7deg, -1deg);
    // overflow: hidden;
    // margin-top: -3px;
    // height: 6px;
    // background-image: url("~@/assets/img/link.svg");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position-x: center;
    // background-color: red;
    content: "";
    // border-bottom: 3px solid #226736;
  }
</style>
