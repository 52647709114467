<template lang="pug">
.locale-changer
  q-select(
    dense,
    borderless
    emit-value,
    map-options,
    v-model="currentLang",
    @input="changed",
    :options="langs",
    :behavior="($q.screen.gt.xs)?'menu':'dialog'"
  )
    template(v-slot:prepend)
      q-icon(color="primary", size="small" :name="matTranslate")
    template(v-slot:selected-item="scope")
      .text-weight-medium(v-bind="scope.itemProps", v-on="scope.itemEvents") {{ scope.opt.label }}
</template>

<script>
  import { Settings } from "luxon";
  import langs from "../langs";
  import { getUsableLocale, loadLanguageAsync } from "../i18n";
  /* eslint-disable no-undef */
  const langmap = require("@/language-mapping-list");
  let lang_rtl = {
    ur: "ar",
    fa: "ar",
    pa: "ar",
    ar: "ar",
  };

  export default {
    name: "locale-changer",
    mounted() {
      this.currentLang = getUsableLocale();

      // console.log(this.currentLang);

      this.changed(this.currentLang);

      this.$router.onReady(() => {
        // console.log(this.$route);
        if (this.$route.params.locale) {
          // console.log("Changing route locale");
          this.currentLang = this.$route.params.locale;
          this.changed(this.$route.params.locale);
        }
      });
    },
    created() {
      // this.changed(getUsableLocale());
    },
    methods: {
      codeToLang(code) {
        let map = langmap[code];
        return map ? map["nativeName"] : code;
      },
      async changed(val) {
        // Set luxon:
        Settings.defaultLocale = val;
        await loadLanguageAsync(val);

        if (lang_rtl[val]) {
          let lang = await import("quasar/lang/ar");
          this.$q.lang.set(lang.default);
        } else {
          let lang = await import("quasar/lang/en-us");
          this.$q.lang.set(lang.default);
        }
      },
    },
    data: () => {
      return { currentLang: "en" };
    },
    computed: {
      langs() {
        let lngs = [];
        for (let l of langs)
          lngs.push({
            label: this.codeToLang(l),
            value: l,
          });
        return lngs;
      },
    },
  };
</script>

<style scoped></style>
